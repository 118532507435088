<template>
  <Navigation/>
  <Marquee
    headline="Under construction"
    subhead="Check back soon for more information."
    :imgSrc="marqueeImg"
    ctaText="Learn more"/>
    <section class="d-section">
      <Header text="What We Do" size="2"/>
      <div class="info-card">
        <InfoCard :image="numbers"
                  title="Knowledge"
                  description="With a knowledge base that has roots deep in Information Technology, we offer the most bang for your buck during consultation."/>
        <InfoCard :image="computer"
                  title="Passion"
                  description="A love for technology and a passion for helping people, we work on your project as if it was our very own."/>
        <InfoCard :image="accomplish"
                  title="Success"
                  description="Seeing our clients succeed is our only goal. From consultation to custom development, our expertise is your expertise."/>
      </div>
      <Header text="About this Site" size="2"/>
      <TextBlock text="This site serves as a sandbox for Web Development. Currently built with VueJS on the front and Spring Boot on the back."/>
    </section>
  <Footer/>
</template>

<script>

/**** Components ****/
import Navigation from './components/navigation/navigation.vue';
import Header from './components/header/header.vue';
import Marquee from './components/marquee/marquee.vue';
import InfoCard from './components/info-card/info-card.vue';
import TextBlock from './components/text-block/text-block.vue';
import Footer from './components/footer/footer.vue';

/**** Images ****/
import Numbers from './assets/images/numbers.jpg';
import Computer from './assets/images/computer.jpg';
import Accomplish from './assets/images/accomplish.jpg';
import MarqueeImage from './assets/images/marquee.jpg';

export default {
  name: 'App',
  components: {
    Navigation,
    Header,
    Marquee,
    InfoCard,
    TextBlock,
    Footer
  },
  data () {
    return {
      numbers: Numbers,
      computer: Computer,
      accomplish: Accomplish,
      marqueeImg: MarqueeImage,
    }
  }
}
</script>

<style land="scss">
@font-face {
  font-family: OpenSans-Regular;
  src: url('./assets/fonts/OpenSans-Regular.1b0809d5.ttf');
}

@font-face {
  font-family: OpenSans-Light;
  src: url('./assets/fonts/OpenSans-Light.ed41bedf.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: OpenSans-Regular;
}

.d-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #d2d2d2;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
