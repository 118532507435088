<template>
    <div class="header" :class="{center: centered }">
      <h1 v-if="size == 1">{{text}}</h1>
      <h2 v-if="size == 2">{{text}}</h2>
      <h3 v-if="size == 3">{{text}}</h3>
      <h4 v-if="size == 4">{{text}}</h4>
      <h5 v-if="size == 5">{{text}}</h5>
      <h6 v-if="size == 6">{{text}}</h6>
    </div>
</template>
<script>
export default {
    props: {
        text: String,
        size: String,
        centered: Boolean
    }
}
</script>

<style src="./header.scss" lang="scss"></style>
