<template>
    <div class='nav'>
        <button>
          Services
        </button>
        <button>
            About
        </button>
        <button>
            Contact Us
        </button>
    </div>
</template>
<script>
    export default {
        data () {
            return {

            }
        }
    }
</script>
<style src="./navigation.scss" lang="scss"></style>
