<template>
    <div class='marquee'>
      <div class='marquee__marquee-text'>
        <h1>{{headline}}</h1>
        <h2>{{subhead}}</h2>
        <Button :text="ctaText"/>
      </div>
        <img :src="imgSrc" class="marquee__bg"/>
        <div v-if="hasUnderlay" class='marquee__underlay'></div>
    </div>
</template>
<script>
import Button from '@/components/button/button.vue';

    export default {
      components: {
        Button: Button
      },
      props: {
          headline: String,
          subhead: String,
          hasUnderlay: Boolean,
          imgSrc: String,
          ctaText: String
      }
    }
</script>
<style src="./marquee.scss" lang="scss"></style>
