<template>
    <div class="info-card__card">
        <div class="info-card__img">
            <img :src="image"/>
        </div>
        <div class="info-card__content">
            <h3>{{title}}</h3>
            <p>{{description}}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        image: String,
        title: String,
        description: String
    }
}
</script>

<style src="./info-card.scss" lang="scss"></style>