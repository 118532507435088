<template>
    <div class="text-block">
      <p>{{text}}</p>
    </div>
</template>
<script>
export default {
    props: {
        text: String
    }
}
</script>

<style src="./text-block.scss" lang="scss"></style>
